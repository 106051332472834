// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import Barba from 'barba.js'
import modernizr from './vendors/modernizr.js';
import svg4everybody from 'svg4everybody'
import BarbaView from './BarbaView';
import PageTransition from './PageTransition';
import globalFunctions from './globalFunctions';
// import Link from '../_modules/link/link';

$(() => {
  svg4everybody();
  BarbaView();
  Barba.Pjax.start();
  globalFunctions();
  Barba.Prefetch.init();
  PageTransition();
});
