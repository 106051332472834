'use strict';

import {exploreSeq, projectDescriptionSeq, navSeq, heroMiniSeq, heroHintsSeq} from '../../_scripts/globalAnimations';
import gsap from 'gsap';
import ScrollMagic from 'ScrollMagic';
// import TweenMax from 'animation.TweenMax';
import addIndicators from 'debug.addIndicators';


export default class Hero {
  constructor() {
    this.name = 'hero';
    console.log('%s module', this.name.toLowerCase());

    let heroController = new ScrollMagic.Controller();
    let heroScene = new ScrollMagic.Scene({
      triggerHook: 0.225,
      tweenChanges: true
    });


    let _scrollScene = ()=>{
      let heroContent = document.querySelector('.hero .hero__content');
      let heroBackdrop = document.querySelector('.hero .hero__backdrop');
      let heroActions = document.querySelectorAll('.hero .hero__content__actions .list-item');
      let heroAnimationDuration = heroContent.offsetHeight;
      let mobileUp = Modernizr.mq('(min-width: 640px)');

      let tweenContent = gsap.to(heroContent, 1, {
        opacity: 0,
        y: heroContent.offsetHeight*0.25
      })

      let tweenBackdrop = gsap.to(heroBackdrop, 1, {
        opacity: 0,
        y: 50
      })

      let tweenheroActions = gsap.to(heroActions, 1, {
        opacity: 0,
        y: 100,
        stagger: 0.05
      })

      let animation = new gsap.timeline()
      .add([tweenheroActions, tweenContent, tweenBackdrop])

      heroScene = new ScrollMagic.Scene({
        offset: 0,
        duration: heroAnimationDuration*0.5,
        triggerHook: 0.225,
        tweenChanges: true
      })
      .setTween(animation)
      // .addIndicators({
      //   name: 'Hero'
      // })
      .addTo(heroController);


    }

    let _destroy = ()=>{
      heroScene.destroy(true);
    }


    return {
      scrollScene: _scrollScene,
      destroy: _destroy
    }

  }
}
