'use strict';

import {exploreSeq, projectDescriptionSeq, navSeq, heroMiniSeq, heroHintsSeq} from '../../_scripts/globalAnimations';

export default class HeroActions {
  constructor() {
    this.name = 'hero actions';
    console.log('%s module', this.name.toLowerCase());

    let btnExplore = document.querySelector('.action-explore');
    let btnDescription = document.querySelector('.action-description');
    let descriptionBack = document.querySelector('.hero__description .back');
    let heroHints = document.querySelectorAll('.hero__content__actions .action .icon');

    let _init = ()=>{
      btnExplore.addEventListener('click', ()=>{
        exploreSeq().animation.play();
      });
      btnDescription.addEventListener('click', ()=>{
        heroMiniSeq().animation.reverse(0);
        setTimeout(()=>{
          projectDescriptionSeq().animation.play()
        }, 900);
      });
      descriptionBack.addEventListener('click', ()=>{
        projectDescriptionSeq().animation.reverse(0);
        document.body.classList.remove('description--opened');
        setTimeout(()=>{
          heroMiniSeq().animation.play(0)
        }, 600)
        // navSeq().animation.play();
        document.body.classList.remove('overlay--opened')
      });
      heroHints.forEach((el)=>{
        el.addEventListener('mouseenter', ()=>{
          heroHintsSeq(el.nextElementSibling).animation.play();
        });
        el.addEventListener('mouseleave', ()=>{
          heroHintsSeq(el.nextElementSibling).animation.reverse(0);
        });
      })
    };
    _init();

  }
}
