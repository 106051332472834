'use restrict'
import $ from 'jquery'
import gsap from 'gsap';
// import { AttrPlugin } from 'gsap';
// import TweenMax from 'gsap';
import SplitText from './vendors/SplitText';
import ScrollTo from './vendors/ScrollToPlugin';
import mdQuery from './globalConfig';

// gsap.registerPlugin( AttrPlugin);


export function navListSeq(
  list = document.querySelectorAll('.project-list'),
  items = document.querySelectorAll('.project-list .list-item'),
  callback
){
  let back = document.querySelector('.site__navigation .back');

  let listTween = gsap.fromTo(list, 0.2, {
    opacity:0,
    display: 'none'
  },{
    opacity:1,
    display: 'flex'
  });
  let itemsTween = gsap.fromTo(items, 0.4, {
    opacity:0,
    x: 20,
  }, {
    opacity:1,
    x: 0,
    stagger:0.05
  });
  let backTween = gsap.fromTo(back, 0.2, {
    display: 'none',
    opacity: 0,
    x: 20,
  }, {
    display: 'flex',
    opacity: 1,
    x: 0,
    delay: 0.4,
    onComplete: ()=>{
      callback;
      document.body.classList.add('overlay--opened');
    }
  })


  let animation = new gsap.timeline()
                              .add(listTween)
                              .add([itemsTween, backTween])
  return {
    animation: animation
  }
}

export function projectListHoverSeq(callback){
  let projectsMedia = document.querySelector('.project-list .project-list__media');


  let projectsMediaTween = gsap.fromTo(projectsMedia, 0.4, {
    opacity: 0,
    x: 50
  }, {
    opacity: 1,
    x: 0
  })

  let projectMediaAnimation = new gsap.timeline().add(projectsMediaTween);

  return {
    animation: projectMediaAnimation,
  }
}

export function projectListDisableSeq(callback){
  let projectsMute = document.querySelectorAll('.project-list .list .mute');

  let projectsMuteTween = gsap.fromTo(projectsMute, 0.4, {
    opacity: 1,
    x: -50
  }, {
    opacity: 0,
    x: 0
  })

  let projectsMuteAnimation = new gsap.timeline().add(projectsMuteTween);

  return {
    animation: projectsMuteAnimation,
  }
}

export function aboutSeq(callback){
  let about = document.querySelector('.about');
  let back = document.querySelector('.site__navigation .back');
  let experiencesSection = [
    document.querySelector('.about .experiences .title--section'),
    document.querySelectorAll('.about .experiences .experience')
  ];
  let skillsetsSection = [
    document.querySelector('.about .skillsets .title--section'),
    document.querySelectorAll('.about .skillsets .skillset')
  ];
  let academicsSection = [
    document.querySelector('.about .academics .title--section'),
    document.querySelectorAll('.about .academics .academic'),
  ];
  let testimonySection = [
    document.querySelector('.about .testimony .title'),
    document.querySelectorAll('.about .testimony p'),
  ]
  let clientsSection = [
    document.querySelector('.about .clients .title--section'),
    document.querySelector('.about .clients .list'),
  ];

  let aboutTween = gsap.fromTo(about, 0.2, {
    opacity:0,
    display: 'none',
  },{
    opacity:1,
    display: 'block',
    onComplete: ()=>{
      document.body.classList.add('overlay--opened');
    }
  });

  let testimonyTween = gsap.fromTo(testimonySection, 0.4, {
    opacity:0,
    x: 20,
  }, {
    opacity:1,
    x: 0,
    stagger: 0.05
  });

  let experiencesTween = gsap.fromTo(experiencesSection, 0.4, {
    opacity:0,
    x: 20,
  }, {
    opacity:1,
    x: 0,
    stagger: 0.05
  });

  let skillsetsTween = gsap.fromTo(skillsetsSection, 0.4, {
    opacity:0,
    x: 20,
  }, {
    opacity:1,
    x: 0,
    stagger: 0.05
  });

  let academicsTween = gsap.fromTo(academicsSection, 0.4, {
    opacity:0,
    x: 20,
  }, {
    opacity:1,
    x: 0,
    stagger: 0.05
  });

  let clientsTween = gsap.fromTo(clientsSection, 0.4, {
    opacity:0,
    x: 20,
  }, {
    opacity:1,
    x: 0,
    stagger: 0.05
  });

  let backTween = gsap.fromTo(back, 0.2, {
    display: 'none',
    opacity: 0,
    x: 20,
  }, {
    display: 'flex',
    opacity: 1,
    x: 0,
    delay: 0.4,
    onComplete: ()=>{
      callback;
    }
  })
  let aboutAnimation = new gsap.timeline()
                              .add(aboutTween)
                              .add(backTween)
                              .add([testimonyTween, experiencesTween, skillsetsTween, clientsTween, academicsTween])
  return {
    animation: aboutAnimation
  }
}


export function homeSeq(callback){
  let profile = document.querySelector('.profile');
  let cover = document.querySelector('.cover');
  let coverItems = [
      document.querySelector('.cover .cover__title-group'),
      document.querySelector('.cover .cover__content'),
      document.querySelector('.cover .cover__email'),
      document.querySelector('.cover .cover__social-list')
  ]
  let profileItems = document.querySelectorAll('.profile .experience .list__item');

  let profileTween = gsap.fromTo(profile, 0.4, {
    opacity: 0,
    x: -50
  },{
    opacity: 1,
    x: 0
  });

  let coverTween = gsap.fromTo(cover, 0.4, {
    opacity: 0,
    x: 50
  },{
    opacity: 1,
    x: 0
  });

  let coverItemsTween = gsap.fromTo(coverItems, 0.4, {
    opacity: 0,
    x: 20
  }, {
    opacity: 1,
    x: 0,
    stagger: 0.1,
    onComplete: ()=>{
      callback;
    }
  },
);

  let profileItemsTween = gsap.fromTo(profileItems, 0.4, {
    opacity: 0,
    x: -20
  }, {
    opacity: 1,
    x: 0,
    stagger: 0.1
  },
);

  let homeInitAnimarion = new gsap.timeline()
                              .add([profileTween, coverTween])
                              .add([coverItemsTween, profileItemsTween]);

  return {
    animation: homeInitAnimarion
  }
}

export function navSeq(callback){
  let nav = document.querySelector('.navigation');
  let navItems = document.querySelectorAll('.navigation .menu-list .list-item');

  let navItemsTween = gsap.fromTo(navItems, 0.2, {
    opacity: 0,
    y: 50
  }, {
    opacity: 1,
    y: 0,
    stagger:0.05,
    onComplete: ()=>{
      callback;
    }
  })

  let navTween = gsap.fromTo(nav, 0.2, {
    opacity: 0
  }, {
    opacity: 1
  })

  // let navItemsTweenMobil = TweenMax.to(navItems, 0.2, {
  //   opacity: 0,
  //   y: 50,
  // })

  let navExitAnimation = new gsap.timeline()
                              .add(navTween)
                              .add(navItemsTween)
                              .pause();

  return {
    animation: navExitAnimation
  }
}

export function heroSeq(callback){
  let hero = document.querySelector('.hero');
  let heroBackdrop = document.querySelector('.hero .hero__backdrop');
  let heroBackdropTx = document.querySelector('.hero .hero__backdrop .title');
  let heroImg= document.querySelector('.hero .hero__content__img');
  let heroActions= document.querySelectorAll('.hero .hero__content__actions .action');

  let heroBackdropSplitTx = new SplitText(heroBackdropTx, {
    type: "words",
    wordsClass: "word word++"
  })

  $('.hero .hero__backdrop .word').wrapInner('<div class="inner"></div>');
  let word = document.querySelectorAll('.hero .hero__backdrop .inner');


  let heroTween = gsap.fromTo(hero, 1, {
    opacity: 0,
    y: 50,
  }, {
    opacity: 1,
    y: 0,
    delay: 0.4,
  })

  let heroBackdropTween = gsap.fromTo(word, 0.8, {
    opacity: 0,
    y: 30,
  }, {
    opacity: 1,
    y: 0,
    stagger: 0.1,
    onComplete: ()=>{
      setTimeout(()=>{
        heroBackdropSplitTx.revert();
      }, 100);
    }
  })


  let heroImgTween = gsap.fromTo(heroImg, 0.5, {
    opacity: 0,
    y: 50,
  }, {
    opacity: 1,
    y: 0,
  })

  let heroActionsTween = gsap.fromTo(heroActions, 0.5, {
    opacity: 0,
    y: 20,
  }, {
    opacity: 1,
    y: 0,
    stagger: 0.05
  })


  let heroAnimation = new gsap.timeline()
                    .add(heroTween)
                    .add(heroBackdropTween)
                    .add(heroImgTween)
                    .add(heroActionsTween)

  return {
    animation: heroAnimation
  }

}

export function exploreSeq(){
  let vh = window.innerHeight;
  let exploreTween = gsap.to(window, {duration: 2, scrollTo: 400});
  let exploreAnimation = new gsap.timeline().add(exploreTween);
  return {
    animation: exploreAnimation
  }
}


export function projectDescriptionSeq(){
  let projectDescription = document.querySelector('.hero__description');
  let projectBack = document.querySelector('.hero__description .back');
  let vh = window.innerHeight;
  let projectBackTween = gsap.fromTo(projectBack, 0.2, {
    opacity: 0,
    y: -20,
    display: 'none'
  }, {
    opacity: 1,
    y: 0,
    display: 'block'
  })
  let projectDescriptionTween = gsap.fromTo(projectDescription, 0.4, {
    opacity:0,
    y: -(vh*0.25),
    display: 'none'
  },{
    opacity:1,
    y: 0,
    display: 'flex',
    onComplete: ()=>{
      document.body.classList.add('description--opened');
    }
  });
  let projectDescriptionAnimation = new gsap.timeline()
                                    .add(projectDescriptionTween)
                                    .add(projectBackTween);
  return {
    animation: projectDescriptionAnimation
  }
}

export function heroHintsSeq(targetEl){
  let targetTween = gsap.fromTo(targetEl, 0.2, {
    opacity: 0,
    y: 0,
  }, {
    opacity: 1,
    y: 25,
  });
  let targetAnimation = new gsap.timeline().add(targetTween);

  return {
    animation: targetAnimation
  }

}

export function heroMiniSeq(){
  let heroImg= document.querySelector('.hero .hero__content__img');
  let heroActions= document.querySelectorAll('.hero .hero__content__actions .action');
  let vh = window.innerHeight;
  let heroImgTween = gsap.fromTo(heroImg, 0.5, {
    opacity: 0,
    y: vh*0.25,
  }, {
    opacity: 1,
    y: 0,
  })

  let heroActionsTween = gsap.fromTo(heroActions, 0.5, {
    opacity: 0,
    y: vh*0.25,
  }, {
    opacity: 1,
    y: 0,
    stagger:0.05
  })

  let heroMiniAnimation = new gsap.timeline()
                              .add(heroImgTween)
                              .add(heroActionsTween)

  return {
    animation: heroMiniAnimation
  }

}

export function loadingSeq(){
  let loading = document.querySelector('.loading');
  let loadingPercentage = document.querySelector('.loading .loading__percentage');
  let loadingBar = document.querySelector('.loading .loading__bar');


  let loadingTween = gsap.fromTo([loading, loadingBar], 0.2, {
    opacity: 0,
    x: 20,
  }, {
    opacity: 1,
    x: 0
  })

  let loadingBarTween = gsap.fromTo(loadingBar, 0.8, {
    opacity:0,
  }, {
    opacity:1,
    repeat: -1,
    yoyo: true
  })

  let loadingBarTweenReverse = gsap.to(loadingBar, 0.8, {
    opacity:0
  })

  let loadingAnimation = new gsap.timeline().add(loadingTween).pause();
  let loadingBarAnimation = new gsap.timeline().add(loadingBarTween).pause();
  let loadingBarAnimationReverse = new gsap.timeline().add(loadingBarTweenReverse).pause();
  return {
    animation: loadingAnimation,
    animationBar: loadingBarAnimation,
    animationBarReverse: loadingBarAnimationReverse
  }
}

export function mobileNavigationSeq(){
  let nav = document.querySelector('.navigation');
  let navMenu = document.querySelector('.navigation .menu-list');
  let navMenuItems = document.querySelectorAll('.navigation .menu-list .list-item');
  let toggle = document.querySelector('.mobile-navigation .toggle--mobile-navigaiton');
  let toggleTxtMenu = document.querySelector('.mobile-navigation .toggle--mobile-navigaiton .button__text--menu');
  let toggleTxtBack = document.querySelector('.mobile-navigation .toggle--mobile-navigaiton .button__text--back');

  let navTweenOpen = gsap.to(nav, 0.2, {
    display: 'flex',
    opacity: 1,
  })

  let navTweenClose = gsap.to(nav, 0.2, {
    display: 'none',
    opacity: 0,
  })

  let navMenuTweenOpen = gsap.to(navMenu, 0.2, {
    display: 'flex',
    opacity: 1
  })
  let navMenuTweenClose = gsap.to(navMenu, 0.2, {
    display: 'none',
    opacity: 0
  })

  let navMenuItemsTweenOpen = gsap.to(navMenuItems, 0.2,{
    opacity: 1,
    y: 0,
    stagger: 0.05,
    onComplete: ()=>{
      toggle.dataset.toggled = 'true';
    }
  })
  let navMenuItemsTweenClose = gsap.to(navMenuItems, 0.2, {
    opacity: 0,
    y: 50,
    stagger: 0.05,
    onComplete: ()=>{
      toggle.dataset.toggled = 'false';
    }
  })

  let navTglTweenMenu = gsap.fromTo(toggleTxtMenu, 0.2, {
    opacity: 1,
    y: 0,
    // x: -toggleTxtMenu.offsetWidth*0.5,
  }, {
    opacity: 0,
    y: -50,
    // x: -toggleTxtMenu.offsetWidth*0.5,
  });

  let navTglTweenBack = gsap.fromTo(toggleTxtBack, 0.2, {
    opacity: 0,
    y: 50,
    // x: -toggleTxtBack.offsetWidth*0.5,
  }, {
    opacity: 1,
    y: 0,
    // x: -toggleTxtBack.offsetWidth*0.5,
  });


  let animationOpen = new gsap.timeline()
                      .add(navTweenOpen)
                      .add(navMenuTweenOpen)
                      .add(navMenuItemsTweenOpen)
                      .pause();

  let animationClose = new gsap.timeline()
                      .add(navTweenClose)
                      .add(navMenuTweenClose)
                      .add(navMenuItemsTweenClose)
                      .pause();

  let animationTglMenu = new gsap.timeline()
                          .add(navTglTweenMenu)
                          .add(navTglTweenBack)
                          .pause();


  return {
    animationOpen: animationOpen,
    animationClose: animationClose,
    animationTglMenu : animationTglMenu
  }

}


export function blogLoadSeq(){
  let container = document.querySelectorAll('.container')
  // let blogBody = document.querySelector('.blog__body')

  let blogTween = gsap.fromTo(container, 1, {
    opacity:0,
    y:100
  }, {
    opacity:1,
    y:0,
    stagger:0.2
  })

  let blogAnimation = new gsap.timeline()
                      .add(blogTween)
                      .pause();

  return {
    animation : blogAnimation
  }

}
