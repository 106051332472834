'use strict';
import gsap from 'gsap';

export default class ScrollToTop {
  constructor() {
    this.name = 'scroll-to-top';
    console.log('%s module', this.name.toLowerCase());
    let scrollToTop = document.querySelector('.scroll-to-top');

    let _init = (()=>{
      scrollToTop.addEventListener('click', ()=>{
        gsap.to(window, 0.4, {
          scrollTo: 0
        })
      })
      window.addEventListener('scroll', ()=>{
        if(window.pageYOffset>window.innerHeight){
          gsap.to(scrollToTop, 0.2, {
            opacity: 1,
            y: 0
          });
        } else {
          gsap.to(scrollToTop, 0.2, {
            opacity: 0,
            y: 20
          });
        }
      })
    })();

  }
}
