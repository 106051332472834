'use strict';
import Navigation from '../_modules/navigation/navigation';
import MobileNavigation from '../_modules/mobile-navigation/mobile-navigation';
import ProjectList from '../_modules/project-list/project-list';
// import Menu from '../_modules/menu/menu';

export default()=>{
  let siteNavigation = new Navigation();
  siteNavigation.render();
  let siteProjectList = new ProjectList();
  siteProjectList.render();
  let siteMobileNavigation = new MobileNavigation();
  siteMobileNavigation.render();
  // let siteMenu = new Menu();
}
