'use strict';
import ScrollMagic from 'ScrollMagic';
import gsap from 'gsap';
import TweenMax from 'animation.TweenMax';
import addIndicators from 'debug.addIndicators';


export default class MyBlog {
  constructor() {
    this.name = 'blog';
    console.log('%s module', this.name.toLowerCase());

    let titleController = new ScrollMagic.Controller();
    let titleScene = new ScrollMagic.Scene({
      triggerHook: 0.225,
      tweenChanges: true
    });


    let _render = ()=>{
      let mobileUp = Modernizr.mq('(min-width: 640px)');
      let titleGroup = document.querySelector('.blog__title-group');
      let title = document.querySelector('.blog__title-group .title');
      let subtitle = document.querySelector('.blog__title-group .subtitle');
      let animationDuration = titleGroup.offsetHeight;

      let tweenTitleGroup = gsap.to(titleGroup, 0.5, {
        opacity:0,
        scale: 0.95,
        y: titleGroup.offsetHeight*0.5
      })

      let animation = new gsap.timeline().add(tweenTitleGroup);

      titleScene = new ScrollMagic.Scene({
        offset: 0,
        duration: animationDuration*1.5,
        triggerHook: 0,
        tweenChanges: true
      })
      .setTween(animation)
      // .addIndicators({
      //   name: 'blog'
      // })
      .addTo(titleController);

    }

    let _destroy = ()=>{
      titleScene.destroy(true);
    }

    return {
      render : _render,
      destroy: _destroy
    }

  }
}
