import gsap from 'gsap'
// import blender from 'color-blend'
import chroma from 'chroma-js'


export default()=>{
  let bgColor, titleColor;
  let container = document.querySelectorAll('.container--bgColor');
  let timestamp = document.querySelector('.blog__timestamp')
  let titleGroup = [document.querySelector('.blog__title-group .title'),
                    document.querySelector('.blog__title-group .subtitle')];
  // let randomBgColor = ()=>{
  //   let mySelection = ['#FFA07A', '#FFB6C1', '#FFA07A', '#FF6347', '#FAFAD2', '#FFE4B5', '#E6E6FA', '#90EE90', '#E0FFFF', '#B0C4DE', '#FFF8DC', '#FFFAFA', '#D3D3D3']
  //   return mySelection[Math.floor(Math.random() * mySelection.length)]
  // }

  let randomBgColor = ()=>{
    let color = '#';
    let steps;
    for(steps=0;  steps<3; steps++) {
      color += (Math.random()*256|225).toString(16).toUpperCase();
    }
    return color;
  }

  bgColor = randomBgColor();
  titleColor = chroma(bgColor).darken(2).saturate(2).hex();

  // let TitleColor = blender.normal(backdrop,  source);
  gsap.to(container, 2, {
    backgroundColor: bgColor
  })
  gsap.to([titleGroup, timestamp], 2, {
    color: titleColor
  })
}
