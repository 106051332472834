'use strict';

import {mobileNavigationSeq} from '../../_scripts/globalAnimations'
import {mdQuery} from '../../_scripts/globalConfig'

export default class MobileNavigation {
  constructor() {
    this.name = 'mobile-navigation';
    console.log('%s module', this.name.toLowerCase());
    this.toggle = document.querySelector('.mobile-navigation .toggle--mobile-navigaiton');
    this.toggleTxt = document.querySelector('.mobile-navigation .toggle--mobile-navigaiton .button__text');

    let _init = (()=>{
    })();

    let _mobileNavTglEvent = ()=>{
      if( this.toggle.dataset.toggled === 'false' || !this.toggle.dataset.toggled  ){
        mobileNavigationSeq().animationOpen.play();
        mobileNavigationSeq().animationTglMenu.play();
        // this.toggle.dataset.toggled = 'true';
      }else{
        mobileNavigationSeq().animationClose.play();
        mobileNavigationSeq().animationTglMenu.reverse(0);
        // this.toggle.dataset.toggled = 'false';
      }
    }

    let _render = ()=>{
      this.toggle.addEventListener('click', ()=>{
        _mobileNavTglEvent();
      })

      if(mdQuery.mobile){
        window.addEventListener('keydown', ()=>{
          if(event.defaultPrevented){

          }
          switch (event.key) {
            case 'Escape':
              _mobileNavTglEvent();
              break;
            default:
          }
        }, true)
      }


    }

    return {
      render : _render
    }
  }
}
