'use strict';
import {projectListHoverSeq} from '../../_scripts/globalAnimations';
import $ from 'jquery';

export default class ProjectList {
  constructor() {
    this.name = 'project-list';
    console.log('%s module', this.name.toLowerCase());
    let mobileUp = Modernizr.mq('(min-width: 640px)');

    let _init = (()=>{
      $('.project-list .list-item').mouseenter(function(event) {
        /* Act on the event */
        $(this).siblings('.list-item').addClass('mute');
        $(this).addClass('active');
      });
      $('.project-list .list-item').mouseleave(function(event) {
        /* Act on the event */
        $(this).siblings('.list-item').removeClass('mute');
        $(this).removeClass('active');
      });
    })();

    let _insertCurrentImg = ()=>{
      let activeMenu = document.querySelector('.project-list .list-item.active');
      let currentImg = activeMenu.getAttribute('data-img');
      let navImg = document.querySelector('.project-list img');
      navImg.src = currentImg;
    }

    let _render = ()=>{
      if(mobileUp){
        $('.project-list .list-item').mouseenter(()=> {
          _insertCurrentImg();
          projectListHoverSeq().animation.play();
        });
        $('.project-list .list').mouseleave((event)=> {
          /* Act on the event */
          projectListHoverSeq().animation.reverse(0);
        });
        
      }
    }

    return {
      render: _render
    }

  }
}
