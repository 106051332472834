'use strict';
import gsap from 'gsap';
import baffle from 'baffle';

export default class Cover {
  constructor() {
    this.name = 'cover';
    console.log('%s module', this.name.toLowerCase());

    let titles = document.querySelectorAll('.cover__title-group .title .text');
    let titlesTotle = titles.length;
    let transitionTime = 100;
    let pauseTime = 3000;

    let _loppThrough = ()=>{
      gsap.to(titles, transitionTime/1000, {
        opacity: 1,
        stagger: pauseTime/1000,
        onComplete: ()=>{

          let b = baffle(titles)
          .text(currentText => currentText)
          .start()
          .set({
            characters : '01',
            speed: 100
          })
          .reveal(900, 200);

          setTimeout(()=>{
            gsap.to(titles, transitionTime/1000, {
              opacity: 0,
              stagger: pauseTime/1000
            })
          }, transitionTime*20)
        }
      });
    }

    let _infinity = ()=>{
      _loppThrough();
      window.setInterval (()=>{
        _loppThrough();
      }, titlesTotle*(transitionTime+pauseTime));
    }

    let _init = (()=>{
      _infinity();
    })();

  }
}
