import $ from 'jquery';
import Barba from 'barba.js';
import Cover from '../_modules/cover/cover';
import Hero from '../_modules/hero/hero';
import MyBlog from '../_modules/blog/blog';
import HeroActions from '../_modules/hero/heroActions';
import ScrollToTop from '../_modules/scroll-to-top/scroll-to-top';
import {homeSeq, heroSeq, navSeq, blogLoadSeq} from './globalAnimations';
import backgroundColor from './backgroundColor';
// import MultiColSlider from '../_modules/multi-col-slider/multi-col-slider';

export default()=>{

  let projectHero = new Hero();
  let blogHero = new MyBlog();
  //////////////////////////////////
  //************HOME*************//
  //////////////////////////////////

  let Home = Barba.BaseView.extend({
    namespace: 'home',
    onEnter: function() {
        // The new Container is ready and attached to the DOM.
        document.body.classList.add('page-index');
        document.body.classList.remove('page-project');
        document.body.classList.remove('page-about');
        document.body.classList.remove('page-blog');
  },
    onEnterCompleted: function() {
        // The Transition has just finished.
        homeSeq().animation.play();
        navSeq().animation.play();
        let myCover = new Cover();
    },
    onLeave: function() {
        // A new Transition toward a new page has just started.
    },
    onLeaveCompleted: function() {
        // The Container has just been removed from the DOM.
    }
  });
  // Don't forget to init the view!
  Home.init();
  //////////////////////////////////
  //************PROJECTS*************//
  //////////////////////////////////
  let Project = Barba.BaseView.extend({
    namespace: 'project',
    onEnter: function() {
        // The new Container is ready and attached to the DOM.
        document.body.classList.add('page-project');
        document.body.classList.remove('page-index');
        document.body.classList.remove('page-about');
        document.body.classList.remove('page-blog');
    },
    onEnterCompleted: function() {
        // The Transition has just finished.
        // heroSeq().animation.play();
        let projectHeroActions = new HeroActions();
        new ScrollToTop();
        projectHero.scrollScene();
        heroSeq().animation.play();
        navSeq().animation.play();
    },
    onLeave: function() {
        // A new Transition toward a new page has just started.
        projectHero.destroy();

    },
    onLeaveCompleted: function() {
        // The Container has just been removed from the DOM.
    }
  });
  // Don't forget to init the view!
  Project.init();
  //////////////////////////////////
  //************BLOGS*************//
  //////////////////////////////////
  let Blog = Barba.BaseView.extend({
    namespace: 'blog',
    onEnter: function() {
        // The new Container is ready and attached to the DOM.
        document.body.classList.add('page-blog');
        document.body.classList.remove('page-index');
        document.body.classList.remove('page-project');
        document.body.classList.remove('page-about');
    },
    onEnterCompleted: function() {
        // The Transition has just finished.
        navSeq().animation.play();
        backgroundColor();
        $('pre code').each(function(i, block) {
          hljs.highlightBlock(block);
        });
        blogHero.render();
        blogLoadSeq().animation.play();
    },
    onLeave: function() {
        // A new Transition toward a new page has just started.
    },
    onLeaveCompleted: function() {
        // The Container has just been removed from the DOM.
    }
  });
  // Don't forget to init the view!
  Blog.init();
  //////////////////////////////////
  //************ABOUT*************//
  //////////////////////////////////
  let About = Barba.BaseView.extend({
    namespace: 'about',
    onEnter: function() {
        // The new Container is ready and attached to the DOM.
        document.body.classList.add('page-about');
        document.body.classList.remove('page-index');
        document.body.classList.remove('page-project');
        document.body.classList.remove('page-blog');
    },
    onEnterCompleted: function() {
        // The Transition has just finished.
    },
    onLeave: function() {
        // A new Transition toward a new page has just started.
    },
    onLeaveCompleted: function() {
        // The Container has just been removed from the DOM.
    }
  });
  // Don't forget to init the view!
  About.init();


}
