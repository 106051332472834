import Barba from 'barba.js';
import imagesLoaded from 'imagesloaded';
import {navListSeq, mobileNavigationSeq, projectListHoverSeq, blogLoadSeq, projectListDisableSeq, homeSeq, navSeq, heroSeq, loadingSeq} from './globalAnimations';
import {mdQuery} from './globalConfig'

export default()=>{

  var lastElementClicked;
  let blogList = document.querySelector('.blog-list');
  let blogListItems = document.querySelectorAll('.blog-list .list-item');

  Barba.Dispatcher.on('linkClicked', function(el) {
    lastElementClicked = el;
  });


  /**
   * to project transition
   */
  let toProjectTransition = Barba.BaseTransition.extend({

    start: function(){
      this.originalHint = lastElementClicked;
      let loadingPercentage = document.querySelector('.loading .loading__percentage');
      loadingPercentage.innerHTML = 'loading';
      navSeq().animation.reverse(0);
      projectListHoverSeq().animation.reverse(0);
      loadingSeq().animation.play();
      loadingSeq().animationBar.play();

      Promise
        .all([this.newContainerLoading, this.closeMenu()])
        .then(this.showNewPage.bind(this));

    },
    closeMenu: ()=>{
      let deferred = Barba.Utils.deferred();
      document.body.style.overflow = 'hidden';
      if(mdQuery.mobile){
        mobileNavigationSeq().animationClose.play();
      }
      deferred.resolve()
      return deferred.promise;
    },
    showNewPage: function(){
      let newContainerImgLoad = new imagesLoaded(this.newContainer);
      let loadingPercentage = document.querySelector('.loading .loading__percentage');
      newContainerImgLoad.on('progress', (instance, image)=>{
        var result = image.isLoaded ? 'loaded' : 'broken';
        let percentComplete = instance.progressedCount/instance.images.length;
        loadingPercentage.innerHTML = Math.floor(percentComplete*100) + '%';
      })
      newContainerImgLoad.on('done', ()=>{
        loadingPercentage.innerHTML = null;
        loadingSeq().animation.reverse(0);
        loadingSeq().animationBarReverse.play();
        navListSeq().animation.reverse(0);
        navSeq().animation.play();
        this.newContainer.style.visibility = 'visible';
        document.body.classList.remove('overlay--opened')
        document.body.style.overflow = 'auto';
        this.done()
      })
    }

  });

  /**
   * to blog transition
   */
  let toBlogTransition = Barba.BaseTransition.extend({

    start: function(){
      this.originalHint = lastElementClicked;
      console.log('animation start')
      let loadingPercentage = document.querySelector('.loading .loading__percentage');
      loadingPercentage.innerHTML = 'loading';
      navSeq().animation.reverse(0);
      loadingSeq().animation.play();
      loadingSeq().animationBar.play();

      Promise
        .all([this.newContainerLoading, this.closeMenu()])
        .then(this.showNewPage.bind(this));

    },
    closeMenu: ()=>{
      let deferred = Barba.Utils.deferred();
      document.body.style.overflow = 'hidden';
      if(mdQuery.mobile){
        mobileNavigationSeq().animationClose.play();
      }
      deferred.resolve()
      return deferred.promise;
    },
    showNewPage: function(){
      let newContainerImgLoad = new imagesLoaded(this.newContainer);
      let loadingPercentage = document.querySelector('.loading .loading__percentage');
      newContainerImgLoad.on('progress', (instance, image)=>{
        var result = image.isLoaded ? 'loaded' : 'broken';
        let percentComplete = instance.progressedCount/instance.images.length;
        loadingPercentage.innerHTML = Math.floor(percentComplete*100) + '%';
      })
      newContainerImgLoad.on('done', ()=>{
        loadingPercentage.innerHTML = null;
        loadingSeq().animation.reverse(0);
        loadingSeq().animationBarReverse.play();
        navListSeq(blogList, blogListItems).animation.reverse(0);
        navSeq().animation.play();
        // blogLoadSeq().animation.play();
        this.newContainer.style.visibility = 'visible';
        document.body.classList.remove('overlay--opened')
        document.body.style.overflow = 'auto';
        this.done()
      })
    }

  });

  /**
   * to home transition
   */
   let toHomeTransition = Barba.BaseTransition.extend({

     start: function(){
       this.originalHint = lastElementClicked;

       Promise
         .all([this.newContainerLoading, this.closeMenu()])
         .then(this.showNewPage.bind(this));

     },
     closeMenu: ()=>{
       let deferred = Barba.Utils.deferred();
       document.body.style.overflow = 'hidden';
       navSeq().animation.reverse();
       if(mdQuery.mobile){
         mobileNavigationSeq().animationClose.play();
       }
       deferred.resolve()
       return deferred.promise;
     },
     showNewPage: function(){
       this.newContainer.style.visibility = 'visible';
       navSeq().animation.play();
       homeSeq().animation.play();
       document.body.style.overflow = 'auto';
       this.done();
     }

   });


  /**
   * Next step, you have to tell Barba to use the new Transition
   */

  Barba.Pjax.getTransition = function() {
  let transitionObj = toProjectTransition;
  console.log(lastElementClicked.classList);
  if (lastElementClicked.classList.contains('project-target')){
    transitionObj = toProjectTransition;
  } else if(lastElementClicked.classList.contains('blog-target')){
    transitionObj = toBlogTransition;
  } else if (lastElementClicked.classList.contains('menu-list__index')){
    transitionObj = toHomeTransition;
  }

  // if (Barba.HistoryManager.prevStatus().namespace === 'singleProject') {
  //   transitionObj = toProjectTransition;
  // }
  return transitionObj;
};

}
