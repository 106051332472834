'use strict';
import {navListSeq, mobileNavigationSeq, aboutSeq, navSeq} from '../../_scripts/globalAnimations';
import {mdQuery} from '../../_scripts/globalConfig';

export default class Navigation {
  constructor() {
    this.name = 'navigation';
    console.log('%s module', this.name.toLowerCase());

    let nav = document.querySelector('.site__navigation');
    let navProject = document.querySelector('.navigation .menu-list__projects');
    let navAbout = document.querySelector('.navigation .menu-list__about');
    let navblogs = document.querySelector('.navigation .menu-list__thoughts');
    let projectList = document.querySelector('.project-list');
    let blogList = document.querySelector('.blog-list');
    let blogListItems = document.querySelectorAll('.blog-list .list-item');
    let about = document.querySelector('.about');
    let Back = document.querySelector('.site__navigation .back');

    let _init = (()=>{
    })();

    let _backAnimation = ()=>{
      if (nav.dataset.overlay === 'projectList') {
        navListSeq().animation.reverse(0);
      } else if(nav.dataset.overlay === 'about') {
        aboutSeq().animation.reverse(0);
      } else if(nav.dataset.overlay === 'blogList'){
        navListSeq(
          blogList,
          blogListItems
        ).animation.reverse(0);
      };
      navSeq().animation.play();
      nav.dataset.overlay = null;
      document.body.classList.remove('overlay--opened')
    }

    let _render = ()=>{

      Back.addEventListener('click', ()=>{
        _backAnimation();
      });

    window.addEventListener('keydown', ()=>{
      if(event.defaultPrevented){
      }
      switch (event.key) {
        case 'Escape':
          if(document.body.classList.contains('overlay--opened')){
            _backAnimation();
          }
          break;
        default:
      }
    }, true)

      navAbout.addEventListener('click', ()=>{
        event.preventDefault();
        nav.dataset.overlay = 'about';
        aboutSeq().animation.play();
      });
      navProject.addEventListener('click', ()=>{
        event.preventDefault();
        nav.dataset.overlay = 'projectList';
        navListSeq().animation.play();
        navSeq().animation.reverse(0);
      });
      navblogs.addEventListener('click', ()=>{
        event.preventDefault();
        nav.dataset.overlay = 'blogList';
        navListSeq(
          blogList,
          blogListItems
        ).animation.play();
        navSeq().animation.reverse(0);
      });
    }

    return {
      render: _render
    }

  }
}
